import { handleHelpCommand } from "./help";
import { Command, Params, Headers } from "./types";
import { inputToArguments } from "./utils";
import { validateParams } from "./validate";
import { ValidationError } from "yup";
import { handleAPICommand, APICommandResponse } from "./api";
import { ParserError, parseCommand } from "./parser";

type HandleCommandResult = {
  command: Command;
  chain: Command[];
  params: Params;
  headers: Headers;
  openModal?: boolean;
};

export function handleCommand(
  globalConfig: Command[],
  input: string,
  apiAuth: string,
): string | APICommandResponse | HandleCommandResult {
  const trimmedInput = input.trim();

  if (trimmedInput === "help" || trimmedInput === "?") {
    return handleHelpCommand(globalConfig);
  }

  const args = inputToArguments(trimmedInput);

  try {
    const {
      command,
      chain,
      params,
      headers,
      flags: { help, modal },
    } = parseCommand(args, globalConfig, {
      help: "?",
      modal: "-m",
    });

    if (modal) {
      return { command, chain, params, headers, openModal: true };
    }

    if (help) {
      let isValid = true;
      try {
        validateParams(chain, params, headers);
      } catch (e) {
        isValid = false;
      }
      return handleHelpCommand(globalConfig, command, args, isValid);
    }

    validateParams(chain, params, headers);

    if (command.endpoint) {
      return handleAPICommand(command, params, headers, apiAuth);
    }

    if (command.subcommands)
      return handleHelpCommand(globalConfig, command, args, true);

    throw new ParserError(
      `Command ${command.name} has no endpoint, neither subcommands `,
    );
  } catch (error) {
    if (error instanceof ParserError) return error.message;

    if (error instanceof ValidationError) return `${error.message}`;

    console.error(error);
    return "Unexpected error occurred. Please check browser console.";
  }
}
