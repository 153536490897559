import fp from "lodash/fp";

export const inputToArguments = (input: string): string[] => {
  const args: string[] = [];
  let currentArg = "";
  let inQuote = false;
  let isMulti = false;
  for (let i = 0; i < input.length; i += 1) {
    if (input[i] === '"' || input[i] === "'") {
      inQuote = !inQuote;
      if (!inQuote) {
        args.push(currentArg);
        currentArg = "";
      }
    } else if (input[i] === "[" || input[i] === "]") {
      isMulti = !isMulti;
      if (input[i] === "]") {
        args.push(
          fp.join(
            "",
            inputToArguments(currentArg).map((s) => s.trim()),
          ),
        );
        currentArg = "";
      }
    } else if (input[i] === " " && !inQuote && !isMulti) {
      if (currentArg) {
        args.push(currentArg);
        currentArg = "";
      }
    } else {
      currentArg += input[i];
    }
  }
  if (currentArg) {
    args.push(currentArg);
  }
  return args;
};
