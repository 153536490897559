import { DEFAULT_COMMANDS } from "constants/commands";
import { CommandError, parseCommand } from "./parser";
import { Command } from "./types";

export function autoCompleteSuggestions(
  globalConfig: Command[],
  args: string[],
) {
  if (!args.length) {
    return [];
  }

  const suggestions = [];
  if (args.length === 1) suggestions.push(...DEFAULT_COMMANDS);

  try {
    parseCommand(args, globalConfig, {});
  } catch (error) {
    if (error instanceof CommandError) {
      const commandNames = error.commands.map((command) => command.name);
      suggestions.push(...commandNames);
    }
  }

  const lastArg = args[args.length - 1].toLowerCase();
  return suggestions.filter((suggestion) =>
    suggestion.toLowerCase().startsWith(lastArg),
  );
}
