import axios, { AxiosResponse, CancelTokenSource } from "axios";
import JSONbig from "json-bigint";
import qs from "qs";
import fp from "lodash/fp";

import { API_BASE_URL_VERSIONED } from "constants/env";
import { FieldValues, Command } from "./types";

export type APICommandResponse = {
  promise: Promise<AxiosResponse<any>>;
  source: CancelTokenSource;
};

export function handleAPICommand(
  parsedCommand: Command,
  fields: FieldValues,
  headers: FieldValues,
  apiAuth: string,
): APICommandResponse {
  const { endpoint, method } = parsedCommand;

  // const { "2factorcode": token, ...restFields } = fields;
  const restFields = fp.omit(["2factorcode"], fields);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const cancelToken = source.token;

  if (method === "POST") {
    const promise = axios.request({
      url: endpoint || "",
      baseURL: API_BASE_URL_VERSIONED,
      method: "post",
      headers: {
        ...headers,
        Authorization: `${apiAuth}`,
      },
      cancelToken,
      data: qs.stringify(restFields),
      transformResponse: [
        function (data) {
          return JSONbig({ storeAsString: true }).parse(data);
        },
      ],
    });

    return {
      promise,
      source,
    };
  } else {
    const params: string = restFields ? "?" + qs.stringify(restFields) : "";
    const promise = axios.request({
      url: `${endpoint}${params}` || "",
      baseURL: API_BASE_URL_VERSIONED,
      method: method,
      headers: {
        ...headers,
        Authorization: `${apiAuth}`,
      },
      cancelToken,
      transformResponse: [
        function (data) {
          try {
            return JSONbig({ storeAsString: true }).parse(data);
          } catch (error) {
            throw Error(`Error parsing response JSON data: ${error.message}`);
          }
        },
      ],
    });

    return {
      promise,
      source,
    };
  }
}
