import { AuthResponsePayload } from "terminal/types";

const AUTH_TOKEN_FIELD = "fastio-terminal-auth-token";
const AUTH_EMAIL_FIELD = "fastio-terminal-auth-email";
const AUTH_2FA_FLAG_FIELD = "fastio-terminal-auth-2fa";

export function setAuth(data: AuthResponsePayload) {
  localStorage.setItem(AUTH_EMAIL_FIELD, data.email);
  localStorage.setItem(AUTH_TOKEN_FIELD, data.auth_token);
  localStorage.setItem(AUTH_2FA_FLAG_FIELD, data["2factor"].toString());
}

export function getAuth() {
  return {
    email: localStorage.getItem(AUTH_EMAIL_FIELD) || "",
    token: localStorage.getItem(AUTH_TOKEN_FIELD) || "",
    "2factor": localStorage.getItem(AUTH_2FA_FLAG_FIELD) === "true",
  };
}
