import React, { useState, useEffect } from "react";

const spinnerChars = "⣾⣽⣻⢿⡿⣟⣯⣷";

export default function Spinner() {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((val) => (val + 1) % spinnerChars.length);
    }, 80);

    return () => clearInterval(timer);
  }, []);

  return <span>{spinnerChars[index]}</span>;
}
