import { Command, Params, Headers } from "./types";
import { FIELD_TYPES } from "../constants/fieldTypes";

export interface ParsedItem {
  command: Command;
  chain: Command[];
  params: Params;
  headers: Headers;
}

type TransformRecord<T extends Record<string, string>> = {
  [K in keyof T]: boolean;
};

export interface ParsedCommand<T extends Record<string, string>>
  extends ParsedItem {
  flags: TransformRecord<T>;
}

type Args = string[] & {
  current: number;
  haveNext(): boolean;
};

export class ParserError extends Error {}

export class CommandError extends ParserError {
  constructor(
    message: string,
    public commands: Command[],
  ) {
    super(message);
  }
}

const findCommand = (args: Args, commands: Command[]) => {
  const name = args[args.current++].toLowerCase();
  const command = commands.find((command) => command.name === name);

  if (!command)
    throw new CommandError(`Invalid command entered: ${name}`, commands);

  return command;
};

const parseParams = (args: Args, command: Command): [Params, Headers] => {
  const params: Params = {};
  const headers: Headers = {};

  if (!args.haveNext()) {
    return [params, headers];
  }

  if (command.defaultField) {
    if (command.defaultField.header) {
      headers[command.defaultField.header] = args[args.current++];
    } else {
      params[command.defaultField.name] = args[args.current++];
    }
  }

  const fields = command.fields || [];

  while (true) {
    const inputArg = args[args.current];
    if (!inputArg) {
      break;
    }

    const arg = inputArg.startsWith("--") ? inputArg.slice(2) : inputArg;

    const field = fields.find((field) => field.name === arg);
    if (!field) {
      break;
    }

    args.current++;
    let value = args[args.current];
    if (
      !value &&
      field.type === FIELD_TYPES.BOOLEAN &&
      inputArg.startsWith("--")
    ) {
      value = "true";
    } else {
      args.current++;
    }

    if (field.header) {
      headers[field.header] = value;
    } else {
      params[arg] = value;
    }
  }

  return [params, headers];
};

const parseItem = (args: Args, commands: Command[]): ParsedItem => {
  let command = findCommand(args, commands);
  const chain = [command];
  const [params, headers] = parseParams(args, command);

  if (command.subcommands && args.haveNext()) {
    const subcommand = parseItem(args, command.subcommands);
    command = subcommand.command;
    chain.push(...subcommand.chain);
    Object.assign(params, subcommand.params);
  }

  return { command, chain, params, headers };
};

export const parseCommand = <T extends Record<string, string>>(
  stringArgs: string[],
  commands: Command[],
  flags: T,
): ParsedCommand<T> => {
  const args = stringArgs as Args;
  args.current = 0;
  args.haveNext = () => args.current < args.length;

  const lastArg = args[args.length - 1];

  const flagsValues = Object.fromEntries(
    Object.keys(flags).map((key) => [key, lastArg === flags[key]]),
  ) as Record<keyof T, boolean>;

  if (Object.values(flagsValues).includes(true)) {
    args.pop();
  }

  return {
    ...parseItem(args, commands),
    flags: flagsValues as TransformRecord<T>,
  };
};
