import { ApiMethod, Command, Field } from "terminal";

export type ServerCommand = {
  name: string;
  description: string;
  endpoint?: string | null;
  method?: string | null;
  fields?: (ServerField | ServerField[])[] | null;
  subcommands?: ServerCommand[] | null;
};

type ServerField = {
  name?: string | null;
  description: string;
  required?: boolean;
  type?: string | null;
  min?: number | null;
  max?: number | null;
  regex?: string | null;
  list?: string[] | null;
};

const fixServerField = (
  field: ServerField,
  command: Command,
  requiredWhenNoOtherFields?: string[],
): Field => {
  for (const key in field)
    if ((field as any)[key] === null) (field as any)[key] = undefined;

  if (!field.name) {
    command.defaultField = field as Field;
    field.name = command.name;
  }

  (field as Field).requiredWhenNoOtherFields = requiredWhenNoOtherFields;

  return field as Field;
};

const fixServerFields = (
  fields: (ServerField | ServerField[])[],
  command: Command,
) => {
  const result: Field[] = [];

  for (const name in fields) {
    const field = fields[name];

    if (Array.isArray(field)) {
      const fieldNames = field.map((field) => field.name as string);

      field.forEach((f) => {
        const otherFieldNames = fieldNames.filter((name) => name !== f.name);

        result.push(fixServerField(f, command, otherFieldNames));
      });
    } else {
      result.push(fixServerField(field, command));
    }
  }

  return result;
};

export const fixServerConfig = (commands: ServerCommand[]): Command[] =>
  commands.map(
    ({ name, description, endpoint, method, fields, subcommands }) => {
      const command: Command = {
        name,
        description,
        endpoint: endpoint ?? undefined,
        method: method === null ? undefined : (method as ApiMethod),
        subcommands: subcommands ? fixServerConfig(subcommands) : undefined,
      };

      command.fields = fields ? fixServerFields(fields, command) : undefined;

      return command;
    },
  );
