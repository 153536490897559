import React, { useState, useEffect, ChangeEvent } from "react";
import axios, { AxiosResponse, AxiosError } from "axios";
import _get from "lodash/get";

import { APP_VERSION } from "constants/env";
import Terminal from "components/Terminal";
import "./index.css";
import { Command } from "terminal";
import { ServerCommand, fixServerConfig } from "./fixServerConfig";

type ErrorResponsePayload = {
  error: {
    text: string;
    code: number;
  };
};

type ConfigAPIResponsePayload = {
  result: boolean;
  config: Command[];
  auth: string;
};

const formatError = (error: AxiosError<ErrorResponsePayload>) => {
  if (error.response) {
    const errorCode = _get(error, "response.data.error.code");
    if (errorCode) {
      return `${error.response.data.error.text} (Code: ${errorCode})`;
    } else {
      return "Unexpected error occurred.";
    }
  }
  return error.message;
};

const Home: React.FC = () => {
  const [theme, setTheme] = useState("dark");
  const [globalConfig, setGlobalConfig] = useState<Command[] | null>(null);
  const [lastError, setLastError] = useState<AxiosError | null>(null);
  const [apiAuth, setApiAuth] = useState<string>("");

  const handleChangeTheme = (ev: ChangeEvent<HTMLSelectElement>) =>
    setTheme(ev.target.value);

  const fixAndSetConfig = (config: ServerCommand[]) =>
    setGlobalConfig(fixServerConfig(config as ServerCommand[]));

  useEffect(() => {
    axios
      .request({
        url: "/api/config",
      })
      .then((response: AxiosResponse<ConfigAPIResponsePayload>) => {
        setApiAuth(response.data.auth);
        fixAndSetConfig(response.data.config);
      })
      .catch((error: AxiosError) => setLastError(error));
  }, []);

  return (
    <div className="App">
      {!globalConfig ? (
        lastError ? (
          <>
            <h3 className="App__loadingLabel">Failed to load configuration.</h3>
            <span style={{ textAlign: "center" }}>
              {formatError(lastError)}
            </span>
          </>
        ) : (
          <h3 className="App__loadingLabel">Loading configuration...</h3>
        )
      ) : (
        <>
          <div className="App__header">
            <div>
              Select Theme:&nbsp;
              <select onChange={handleChangeTheme} value={theme}>
                <option value="dark">Dark</option>
                <option value="light">Light</option>
              </select>
            </div>

            <div className="App__header-version">version: {APP_VERSION}</div>
          </div>
          <Terminal
            globalConfig={globalConfig}
            theme={theme}
            welcomeMessage={
              "Welcome to Fast.io management terminal. " +
              'Enter "help" or "?" to display the list of available commands.'
            }
            apiAuth={apiAuth}
          />
        </>
      )}
    </div>
  );
};

export default Home;
